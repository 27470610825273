<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Start of Day
            <span class="statement-head"
              ><v-menu offset-y transition="slide-y-reverse-transition">
                <template v-slot:activator="{ on }">
                  <v-btn @click="reset()" v-on="on" text
                    >{{ selected_statement_type }}
                    <v-icon ml-2>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-subheader>Select Type</v-subheader>
                  <v-list-item
                    v-for="(item, index) in statementTypes"
                    :key="index"
                    @click="selected_statement_type = item"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <v-spacer></v-spacer>
            <!-- <add-button permission="sod-create" @action="form.dialog = true"
              >New Sod</add-button
            > -->

            <!--<v-btn outlined @click="form.dialog = true" ma-0 small>New Fee Head</v-btn>-->
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs2 sm2 v-if="selected_statement_type == 'SOD Date'">
                <v-calendar-field
                  id-val="from_date"
                  v-model="from_date"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>

              <v-flex xs2 sm2 v-if="selected_statement_type == 'SOD Date'">
                <v-calendar-field
                  id-val="to_date"
                  v-model="to_date"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>

              <v-flex xs4 sm4 v-if="selected_statement_type == 'Month'">
                <v-select
                  :items="months"
                  class="pa-0"
                  label="Select Month"
                  v-model="month"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4>
                <search-button permission="sod-read" @action="get()">
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            sort-by="id"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <strong style="display: block; margin-top: 5px">{{
                    item.sod_date
                  }}</strong>
                  <small style="color: #888">{{ item.sod_format }}</small>
                </td>
                <td class="text-xs-left">
                  <v-chip
                    label
                    v-if="item.is_eod === 2 || item.is_eod === 1"
                    class="success white--text"
                    small
                    >DONE
                  </v-chip>
                  <v-chip
                    label
                    v-else-if="item.is_eod === 0"
                    class="error white--text"
                    small
                    >PENDING
                  </v-chip>
                  <v-chip
                    v-else-if="item.is_eod === 3"
                    class="warning white--text"
                    small
                    >HOLDING
                  </v-chip>
                </td>
                <td>
                  <v-chip
                    small
                    class="error white--text"
                    label
                    v-if="item.is_eod === 0"
                  >
                    Not Ready
                  </v-chip>
                  <v-chip
                    label
                    small
                    v-else-if="
                      item.is_eod === 1 && parseInt(item.collected_amount) === 0
                    "
                  >
                    Not required
                  </v-chip>
                  <v-chip
                    v-else-if="
                      item.is_eod === 1 && item.account_posted_at === null
                    "
                    class=""
                    label
                    small
                  >
                    Ready to Post ({{
                      parseFloat(item.collected_amount).numberFormat()
                    }})
                  </v-chip>
                  <div v-else>
                    <p style="margin-top: 10px; margin-bottom: 0">
                      <strong>
                        <v-icon size="small" color="success"
                          >check_circle</v-icon
                        >
                        {{ parseFloat(item.collected_amount).numberFormat() }}
                      </strong>
                    </p>
                    <p style="font-size: 13px; color: #555">
                      Posted by {{ item.accountant }} on
                      {{ item.account_posted_at }}
                    </p>
                  </div>
                </td>
                <td class="text-xs-left">
                  <v-chip
                    small
                    class="error white--text"
                    label
                    v-if="item.is_eod === 0"
                  >
                    Not Ready
                  </v-chip>
                  <v-chip
                    label
                    small
                    v-else-if="item.is_eod !== 0 && item.invoice_posting === 0"
                  >
                    Ready to Post
                  </v-chip>
                  <v-chip
                    label
                    small
                    v-else-if="item.is_eod !== 0 && item.invoice_posting === 2"
                  >
                    Not required
                  </v-chip>
                  <div
                    v-else-if="item.is_eod !== 0 && item.invoice_posting === 1"
                  >
                    <p
                      style="
                        font-size: 13px;
                        color: #555;
                        display: flex;
                        line-height: 1.5;
                      "
                    >
                      <v-icon class="mr-1" color="success">
                        check_circle
                      </v-icon>
                      <span>
                        Posted by {{ item.accountant }} on <br />
                        {{ item.invoice_posted_at }}</span
                      >
                    </p>
                  </div>
                </td>

                <td class="text-xs-right">
                  <div class="mt-2"></div>
                  <view-button
                    :label="
                      (item.is_eod &&
                        item.account_posted_at === null &&
                        parseInt(item.collected_amount) !== 0) ||
                      (item.is_eod && item.invoice_posting === 0)
                        ? 'Review & Post'
                        : 'Review'
                    "
                    permission="sod-read"
                    @click.native="viewStatement(item.sod_date)"
                  />
                  <delete-button
                    v-if="
                      ($auth.isAdmin() &&
                        item.is_eod &&
                        item.account_posted_at === null &&
                        parseInt(item.collected_amount) !== 0) ||
                      (item.is_eod && item.invoice_posting === 0)
                    "
                    permission="sod-delete"
                    @agree="form.delete(item.id)"
                  />&nbsp;
                  <div class="mb-1"></div>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="300px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pa-3">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-flex xs12>
                <v-calendar-field
                  id-val="start_date"
                  v-model="form.sod_date"
                  label="Start Date"
                >
                </v-calendar-field>
              </v-flex>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn
              color="success"
              outlined
              @click="save"
              :disabled="disabledBtn"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/sod"
    ),
    from_date: "",
    to_date:nd.format("YYYY-MM-DD"),
    month: "",
    search: null,
    pagination: {},
    disabledBtn: false,
    statement_types: ["SOD Date", "Month"],
    selected_statement_type: "SOD Date",
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Start of Day", align: "left", value: "sod_date", width: 200 },
      { text: "EOD Status", align: "left", value: "is_eod", width: 150 },
      {
        text: "Collection Posting",
        align: "left",
        value: "is_eod",
        width: 350,
      },
      { text: "Invoice Posting", align: "left", value: "is_eod", width: 350 },
      { text: "Action", align: "left", sortable: false, width: 150 },
    ],
    months: [
      { value: "01", text: "Baisakh" },
      { value: "02", text: "Jestha" },
      { value: "03", text: "Ashad" },
      { value: "04", text: "Shrawan" },
      { value: "05", text: "Bhadra" },
      { value: "06", text: "Asoj" },
      { value: "07", text: "Kartik" },
      { value: "08", text: "Mangsir" },
      { value: "09", text: "Poush" },
      { value: "10", text: "Magh" },
      { value: "11", text: "Falgun" },
      { value: "12", text: "Chaitra" },
      { value: "13", text: "Baisakh(13)" },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    statementTypes() {
      let $this = this;
      return this.statement_types.filter(function (statement) {
        return $this.selected_statement_type !== statement;
      });
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    "form.sod_date": function (v) {
      this.disabledBtn = !!!validateDate(v.dateForm());
      // if () ;
      // else this.disabledBtn = true;
    },
  },

  methods: {
    // queryString() {
    //     let json = this.pagination;
    //     return '?' +
    //         Object.keys(json).map(function (key) {
    //             if (![null, undefined].includes(json[key]))
    //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    //         }).join('&') + '&sodDate=' + this.sodDate.dateForm();
    // },
    editSOD(item) {
      this.form.edit(item);
    },
    get(params) {
      let extraParams = `fromDate=${this.from_date.dateForm()}&toDate=${this.to_date.dateForm()}&month=${
        this.month
      }`;
      this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
        // this.pagination.totalItems = data.meta.total
      });
    },
    save() {
      this.form.sod_date = this.form.sod_date.dateForm();
      this.form.batch_id = this.batch.id;
      this.form.store();
    },
    viewStatement(sod_date) {
      this.$router.push({
        name: "sod-statement",
        params: {
          sod: sod_date,
        },
      });
    },
    reset() {
      this.from_date = "";
      this.to_date = "";
      this.month = "";
    },
  },
};
</script>
<style lang="scss">
.statement-head {
  .v-menu__activator--active {
    background-color: transparent !important;
  }

  .v-btn {
    padding-left: 0px;
  }

  .v-btn__content {
    color: #666 !important;
    font-size: 22px;
    padding-left: 0 !important;
    text-transform: capitalize;
    font-weight: bold !important;
    border-bottom: 2px solid #666;
  }
}
</style>
